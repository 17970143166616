import React from "react"
import "./styles.css"

export default function Home() {
  return (
  <div className="Home">
    OmniiTel<br></br>
    <br></br>
    Website Coming soon<br></br>
    <br></br>
    sales@omnii.tel<br></br>
    </div>
  )
}
